import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomeComponent } from '@features/home/components/home/home.component';

export const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path: 'stock_locations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stock-features/locations/routes').then((m) => m.LOCATION_ROUTES),
  },
  {
    path: 'move_goods',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stock-features/move-goods/routes').then((m) => m.MOVE_GOODS_ROUTES),
  },
  {
    path: 'receipts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stock-features/receive-goods/routes').then((m) => m.RECEIVE_GOODS),
  },
  {
    path: 'deliveries',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stock-features/shipments/routes').then((m) => m.SHIPMENT_ROUTES),
  },
  {
    path: 'pick_orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stock-features/pick-goods/routes').then((m) => m.PICK_GOODS_ROUTES),
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' },
];
