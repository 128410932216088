import { Component, HostListener, inject, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DialogContainerDirective } from '@progress/kendo-angular-dialog';
import { AuthService } from '@auth0/auth0-angular';
import { ApiHttpService } from '@core/services/api-http.service';
import { ApplicationUserService } from '@core/services/application-user.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter, first } from 'rxjs/operators';
import { Observable, Subject, switchMap } from 'rxjs';
import { AppBarComponent } from '@stock-core/components/app-bar/app-bar.component';
import { TranslationLoaderService } from '@core/services/translations-loader.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { LoaderComponent } from '@progress/kendo-angular-indicators';
import { WebcamImage, WebcamModule } from 'ngx-webcam';
import { WebcamService } from '@core/services/webcam.service';
import { ButtonComponent } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'rc-stock-app',
  standalone: true,
  imports: [
    RouterOutlet,
    DialogContainerDirective,
    AppBarComponent,
    AsyncPipe,
    LoaderComponent,
    WebcamModule,
    NgClass,
    ButtonComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  #authService = inject(AuthService);
  #apiService = inject(ApiHttpService);
  #applicationUserService = inject(ApplicationUserService);
  #webcamService = inject(WebcamService);
  #loaderService = inject(TranslationLoaderService);
  user = toSignal(this.#applicationUserService.user$);
  isLoading$ = this.#loaderService.loading$;
  camData = signal<MediaStream | null>(null);
  width: number;
  height: number;
  trigger = new Subject<void>();
  capture: (webcamImage: WebcamImage) => void;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkOrientation();
    const value = window.screen.width > window.screen.height ? window.screen.width : window.screen.height;
    this.width = value;
    this.height = value;
  }
  constructor() {
    this.onResize();
    this.#webcamService.capture.subscribe((value) => {
      if (value) this.capture = value;
    });
    this.#webcamService.camData.subscribe((value) => {
      this.camData.set(value);
    });
    this.redirectIfNotLoggedIn();
    this.#applicationUserService.setApplicationUser();
    this.#authService.idTokenClaims$
      .pipe(
        filter((token) => !!token),
        first(),
        switchMap(() => this.#apiService.get('/cookie')),
      )
      .subscribe();
  }

  redirectIfNotLoggedIn(): void {
    this.#authService.error$.pipe(takeUntilDestroyed()).subscribe((error) => {
      if (error) {
        this.#authService.loginWithRedirect({
          authorizationParams: { redirectUri: '/home', prompt: 'login' },
        });
      }
    });

    this.#authService.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.#authService.loginWithRedirect({ authorizationParams: { redirectUrl: '/home', prompt: 'login' } });
      }
    });
  }

  closeCamera() {
    this.#webcamService.camData.next(null);
  }

  captureImage() {
    this.trigger.next();
  }

  get trigger$(): Observable<void> {
    return this.trigger.asObservable();
  }

  private checkOrientation(): void {
    this.#webcamService.isLandscape.set(window.innerWidth > window.innerHeight);
  }
}
