import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUpdateService {
  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      this.listenForUpdates();
    }
  }

  private listenForUpdates(): void {
    this.swUpdate.versionUpdates.subscribe((response) => {
      if (response.type === 'VERSION_READY') {
        const versionEvent = response as VersionReadyEvent;
        console.log(`New version available: ${versionEvent.latestVersion}`);
        if (confirm('A new version is available. Load the new version?')) {
          this.activateUpdate();
        }
      }
    });
  }

  private activateUpdate(): void {
    this.swUpdate.activateUpdate().then(() => {
      location.reload(); // Reload the app to apply the new version
    });
  }
}
