<ul class="primary-nav__menu">
  <li class="primary-nav__menu-item">
    <a
      routerLink="/stock_locations"
      class="d-flex w-100 align-items-center justify-content-between primary-nav__link"
      attr.aria-label="{{ 'VIEW_LOCATION' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active">
      <div>
        <div class="primary-nav__link">
          <i class="rc-icon-location-info"></i>
          <span class="primary-nav__link--text">{{ "VIEW_LOCATION" | translate | capitalize }}</span>
        </div>
      </div>
      <div>
        <i class="rc-icon-chevron-right"></i>
      </div>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      class="d-flex w-100 align-items-center justify-content-between primary-nav__link"
      attr.aria-label="{{ 'MOVE_GOODS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      routerLink="/move_goods">
      <div>
        <div class="primary-nav__link">
          <i class="rc-icon-forklift"></i>
          <span class="primary-nav__link--text">{{ "MOVE_GOODS" | translate | capitalize }}</span>
        </div>
      </div>
      <div>
        <i class="rc-icon-chevron-right"></i>
      </div>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/receipts"
      attr.aria-label="{{ 'RECEIVE_GOODS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      class="d-flex w-100 align-items-center justify-content-between primary-nav__link">
      <div>
        <div class="primary-nav__link">
          <i class="rc-icon-transfer-in"></i>
          <span class="primary-nav__link--text">{{ "RECEIVE_GOODS" | translate | capitalize }}</span>
        </div>
      </div>
      <div>
        <i class="rc-icon-chevron-right"></i>
      </div>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/deliveries"
      class="d-flex w-100 align-items-center justify-content-between primary-nav__link"
      attr.aria-label="{{ 'SEND_GOODS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active">
      <div>
        <div class="primary-nav__link">
          <i class="rc-icon-transfer-out"></i>
          <span class="primary-nav__link--text">{{ "SEND_GOODS" | translate | capitalize }}</span>
        </div>
      </div>
      <div>
        <i class="rc-icon-chevron-right"></i>
      </div>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/pick_orders"
      attr.aria-label="{{ 'PICK_GOODS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      class="d-flex w-100 align-items-center justify-content-between primary-nav__link">
      <div>
        <div class="primary-nav__link">
          <i class="rc-icon-pick-order"></i>
          <span class="primary-nav__link--text">{{ "PICK_GOODS" | translate | capitalize }}</span>
        </div>
      </div>
      <div>
        <i class="rc-icon-chevron-right"></i>
      </div>
    </a>
  </li>
</ul>
