import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';

import { routes } from './app.stock.routes';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { languageHttpInterceptor } from '@core/interceptors/languageHttpInterceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoMessageService } from '@core/services/kendo-message.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import localeNlBe from '@angular/common/locales/nl-BE';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideServiceWorker } from '@angular/service-worker';
import { TranslationLoaderService } from '@core/services/translations-loader.service';
import { firstValueFrom } from 'rxjs';
import { provideAnimations } from '@angular/platform-browser/animations';
import 'hammerjs';
import { WebcamModule } from 'ngx-webcam';
import { ApplicationUpdateService } from '@core/application-update.service';

registerLocaleData(localeNlBe, 'nl-BE');
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function appInitializerFactory(translate: TranslateService, loaderService: TranslationLoaderService) {
  return () =>
    firstValueFrom(translate.use('nl')).finally(() => {
      loaderService.setLoading(false);
    }); // Load default language
}
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(ApplicationUpdateService),
    provideHttpClient(withInterceptors([authHttpInterceptorFn, languageHttpInterceptor])),
    provideAnimationsAsync(),
    importProvidersFrom(WebcamModule),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    {
      provide: MessageService,
      useClass: KendoMessageService,
    },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, TranslationLoaderService, Sentry.TraceService, ApplicationUpdateService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAuth0({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: environment.auth0.authorizationParams,
      useRefreshTokens: environment.auth0.useRefreshTokens,
      cacheLocation: 'localstorage',
      httpInterceptor: environment.auth0.httpInterceptor,
    }),
    { provide: LOCALE_ID, useValue: 'nl-BE' },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:10000',
    }),
  ],
};
