export const environment = {
  production: true,
  sentry: 'https://dca849ca88671157fb99c3c97dbe63e3@o446789.ingest.us.sentry.io/4507498325868544',
  name: 'Production',
  apiUrl: 'https://prod.warehouse.crownbasenxt.be/api',
  auth0: {
    domain: 'royalcrown.eu.auth0.com',
    clientId: 'eZlxVXcLBvNTKbInqsc3UWW78TGp017V',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://prod.api.royalcrown.be/',
      logoutUrl: window.location.origin,
    },
    useRefreshTokens: true,
    httpInterceptor: {
      allowedList: [
        {
          // Match any request that starts 'https://{yourDomain}/api/v2/' (note the asterisk)
          uri: `https://prod.warehouse.crownbasenxt.be/api/*`,
          tokenOptions: {
            authorizationParams: {
              // The attached token should target this audience
              audience: 'https://prod.api.royalcrown.be/',
              scope: 'openid profile email offline_access',
            },
          },
        },
      ],
    },
  },
};
