<rc-content-with-action>
  <div slot="content">
    <rc-stock-menu></rc-stock-menu>
  </div>
  <div slot="action">
    <button kendoButton class="k-mt-auto w-100" [themeColor]="'primary'" (click)="logout()">
      <i class="rc-icon-logout"></i>
      <span>{{ "LOGOUT" | translate | capitalize }}</span>
    </button>
  </div>
</rc-content-with-action>
