import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CapitalizePipe } from '@core/pipes/capitalize.pipe';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'rc-stock-menu',
  standalone: true,
  templateUrl: './stock-menu.component.html',
  styleUrls: ['./stock-menu.component.scss'],
  imports: [TranslateModule, CapitalizePipe, RouterLink, RouterLinkActive],
})
export class StockMenuComponent {}
