import { Component, inject, TemplateRef } from '@angular/core';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { BackButtonService } from '../../services/BackbuttonService';
import { AppBarContentService } from '@stock-core/services/AppBarContent.service';
import { BackButton } from '@stock-core/models/BackButton';

@Component({
  selector: 'rc-app-bar',
  standalone: true,
  templateUrl: './app-bar.component.html',
  styleUrl: './app-bar.component.scss',
  imports: [ButtonComponent, AsyncPipe, NgClass, NgTemplateOutlet],
})
export class AppBarComponent {
  #router = inject(Router);
  authService = inject(AuthService);
  #backButtonService = inject(BackButtonService);
  #appBarContentService = inject(AppBarContentService);
  backButtonValue?: BackButton;
  #navigationEnd$ = this.#router.events.pipe(
    filter((e: Event) => e instanceof NavigationEnd),
  ) as Observable<NavigationEnd>;
  #navigationStart$ = this.#router.events.pipe(
    filter((e: Event) => e instanceof NavigationStart),
  ) as Observable<NavigationStart>;
  isHome = false;
  contentTemplate: TemplateRef<typeof TemplateRef> | null;
  constructor() {
    this.#navigationEnd$.subscribe((navEnd) => {
      this.isHome = navEnd.url.split('?')[0] === '/home' || navEnd.url.split('?')[0] === '/';
    });
    this.#navigationStart$.subscribe(() => {
      this.#appBarContentService.clearAppBarContent();
    });

    this.#backButtonService.backButtonSubject.subscribe((backButton) => {
      this.backButtonValue = backButton;
    });

    this.#appBarContentService.appBarContent$.subscribe((appBarContent) => {
      this.contentTemplate = appBarContent;
    });
  }

  getAppBarContent() {
    return this.#appBarContentService.appBarContent$;
  }

  navigateToHome(): void {
    this.#router.navigate(['/']);
  }
  navigateBack(backButton: BackButton): void {
    if (backButton.path) {
      this.#router.navigate([backButton.path], { queryParams: backButton.params });
    }
    if (backButton.action) {
      backButton.action();
    }
  }
}
