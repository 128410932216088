import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/stock-app/environment';
import { ApplicationConfig, enableProdMode, mergeApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHammer } from './app/hammer-config';

Sentry.init({
  dsn: environment.sentry,
  environment: environment.name,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/prod\.crownbasenxt\.be/, /^https:\/\/staging\.crownbasenxt\.be/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
enableProdMode();

const browserConfig: ApplicationConfig = {
  providers: [provideAnimations(), provideHammer()],
};
const config = mergeApplicationConfig(appConfig, browserConfig);
bootstrapApplication(AppComponent, config).catch((err) => console.error(err));
