import { Component, inject } from '@angular/core';
import { StockMenuComponent } from '@stock-core/components/stock-menu/stock-menu.component';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { environment } from '@environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateModule } from '@ngx-translate/core';
import { CapitalizePipe } from '@core/pipes/capitalize.pipe';
import { ContentWithActionComponent } from '@stock-core/components/layouts/content-with-action/content-with-action.component';

@Component({
  selector: 'rc-home',
  standalone: true,
  imports: [StockMenuComponent, ButtonComponent, TranslateModule, CapitalizePipe, ContentWithActionComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  #authService = inject(AuthService);

  logout(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.#authService.logout({
      logoutParams: {
        federated: true,
        clientId: environment.auth0.clientId,
        returnTo: window.location.origin,
      },
    });
  }
}
