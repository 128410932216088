@if (camData()) {
  <div class="webcam-container overflow-hidden" (window:resize)="onResize()">
    <button kendoButton class="close-camera-button" (click)="closeCamera()"><i class="rc-icon-times"></i></button>
    <webcam
      [width]="width"
      [height]="height"
      [trigger]="trigger$"
      (imageCapture)="capture($event)"
      [captureImageData]="true"></webcam>
    <button kendoButton class="camera-button" (click)="captureImage()"><i class="rc-icon-eye"></i></button>
  </div>
}
<rc-app-bar [ngClass]="camData() ? 'd-none' : ''"></rc-app-bar>
<main class="main container" [ngClass]="camData() ? 'd-none' : ''">
  <div class="content-wrapper">
    @if (isLoading$ | async) {
      <kendo-loader></kendo-loader>
    } @else {
      <router-outlet></router-outlet>
    }
  </div>
</main>
<div kendoDialogContainer></div>
