<div class="container">
  <!--  @if (!isHome) {-->
  <div [ngClass]="isHome ? 'd-none' : 'd-flex w-100 standard-app-bar justify-content-between'">
    @if (backButtonValue) {
      <button kendoButton class="home-button" (click)="navigateBack(backButtonValue)">
        <i class="rc-icon-arrow-left"></i>
      </button>
    }
    <button kendoButton class="home-button" (click)="navigateToHome()">
      <i class="rc-icon-home"></i>
    </button>
  </div>
  @if (isHome) {
    <div class="d-flex flex-column w-100 align-items-center rc-logo-container">
      <i class="rc-icon-royal-crown"></i>
      <span class="logo">CrownStock</span>
      <span class="user">{{ (authService.user$ | async)?.name }}</span>
    </div>
  }
  @if (contentTemplate) {
    <div class="rc-app-bar-content">
      <div class="content d-flex justify-content-between position-relative">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </div>
  }
</div>
